import { igApi } from '../../api';

export const onWidgetDeletion = async (editorSDKUtils) => {
  const noWidgetsLeft = await isLastWidget(editorSDKUtils);
  if (noWidgetsLeft) {
    await disconnectAllAccounts(editorSDKUtils);
  }
};

const isLastWidget = async (editorSDKUtils): Promise<boolean> => {
  const allRefs = await editorSDKUtils.getAllAppRefComponents();
  return !allRefs?.length;
};

const disconnectAllAccounts = async (editorSDKUtils) => {
  const instance = await editorSDKUtils.getInstance();
  const { instagramAccounts } = await igApi(instance).getAllAccounts();
  if (instagramAccounts?.length) {
    instagramAccounts.forEach((account) => {
      igApi(instance).disconnect(account.id as string);
    });
  }
};
